// Services
import callAPI from "../callApi";
import path from "../variables";

const Otp = {
	getAll: (filter?: any) =>
		callAPI("GET", `${path.base}/otp/valid${filter ? filter : ""}`, "", "getting all Valid Otp"),
	generate: () => callAPI("POST", `${path.base}/otp/generate`, "", "generate new Otp"),
	details: (otp: any) =>
		callAPI("GET", `${path.base}/otp/details?otp=${otp}`, "", "getting Detail of Otp"),
	reset: () => callAPI("PUT", `${path.base}/otp/reset`, null, "resetting all Otp"),
	revoke: (otp: string) =>
		callAPI("POST", `${path.base}/otp/revoke${otp ? otp : ""}`, null, "revoking Otp"),
};

export default Otp;
