// Services
import callAPI from "../callApi";
import path from "../variables";

const Routes = {
	create: (data: any) => callAPI("POST", `${path.base}/agencies`, data, "creating a new station"),
	update: (data: any, id: any) =>
		callAPI("PUT", `${path.base}/agencies/${id}`, data, "updating a new station"),
	getAll: (filter?: string) =>
		callAPI("GET", `${path.base}/agencies${filter ?? ''}`, "", "getting a all stations"),
	get: (id: number) => callAPI("GET", `${path.base}/agencies/${id}`, "", "getting station infos"),

	users: {
		manager: {
			getAll: (stationID: number, userID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/manager?station=${stationID}`,
					"",
					"getting All User Of Current station"
				),
			get: (stationID: number, userID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/manager?station=${stationID}&user=${userID}`,
					"",
					"getting User Detail of Current Station"
				),
			create: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/manager?assign=0`,
					data,
					"Creating New User of Current Station"
				),
			assignRole: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/manager?assign=1`,
					data,
					"Assign New Responsability to User"
				),
			removeAccess: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/manager?remove=1`,
					data,
					"Remove Responsability to User Manager"
				),
			lockAccess: (data: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/manager?lock=1`,
					data,
					"Lock Responsability to User Manager"
				),
		},
		personal: {
			getAll: (stationID: number, filter?: string) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/personal?station=${stationID}${filter ? filter : ""}`,
					"",
					"getting All Personal Of Current station : " + filter
				),
			get: (stationID: number, userID: number) =>
				callAPI(
					"GET",
					`${path.base}/agency/users/personal/detail/1/1`,
					"",
					"getting Personal Detail of Current Station"
				),
			create: (data: any, stationID: any) =>
				callAPI(
					"POST",
					`${path.base}/agency/users/personal${stationID ? "?station=" + stationID : ""}`,
					data,
					"Creating New Personal of Current Station"
				),
		},
	},
};

export default Routes;
