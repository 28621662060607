// Services
import callAPI from "../callApi";
import path from "../variables";

const Role = {
	getAll: (filter?: any) =>
		callAPI("GET", `${path.base}/settings/roles${filter ? filter : ""}`, "", "getting all Roles"),
	create: (data: any, params:string) => callAPI("POST", `${path.base}/settings/roles${params ? params : ''}`, data, "creating new role"),
	update: (data: any, role: any, param: any) =>
		callAPI(
			"PUT",
			`${path.base}/settings/roles/${role}${param ? param : ""}`,
			data,
			"updating a role"
		),
	delete: (role: any, params: any) =>
		callAPI(
			"DEL",
			`${path.base}/settings/roles/${role}${params ? params : ""}`,
			'',
			"deleting a role"
		),
	restore: (data: any, role: any, param: any) =>
		callAPI(
			"DEL",
			`${path.base}/settings/roles/restore${role}${param ? param : ""}`,
			data,
			"restoring a role"
		),
	details: (role: any) =>
		callAPI("GET", `${path.base}/settings/roles/${role}`, "", "getting Detail of Role"),
};

export default Role;
