export default [
	// Dashboard
	{
		name: "dashboard",
		route: "workspace-dashboard",
		allowed_resources: ["all"],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"User",
			"Charger",
			"Driver",
			"Programmer",
		],
		enable: (process.env.VUE_APP_ENABLE_DASHBOARD ?? false) as boolean,
		title: {
			en: "Dashboard",
			fr: "Tableau de bord",
		},
		elements: [],
		icon: {
			active: "/media/icons/dashboard-icon.svg",
		},
	},
	// Agency
	{
		name: "agency",
		allowed_resources: ["station", "retrieve-list-stations-only"],
		user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin"],
		route: "workspace-agency-overview",
		title: {
			en: "Agency",
			fr: "Agences",
		},
		elements: [],
		enable: (process.env.VUE_APP_ENABLE_AGENCY_OVERVIEW ?? false) as boolean,
		icon: {
			active: "/media/icons/agency-icon.svg",
		},
	},
	// Operations
	{
		name: "operations",
		allowed_resources: [
			"passenger",
			"passenger-baggage",
			"package",
			"package-pickup",
			"package-transfer",
			"expense",
		],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"Programmer",
		],
		route: "workspace-operations-overview",
		enable: (process.env.VUE_APP_ENABLE_OPERATION_OVERVIEW ?? false) as boolean,
		title: {
			en: "Operations",
			fr: "Opérations",
		},
		elements: [
			{
				route: "workspace-operations-new-passenger",
				allowed_resources: ["passenger"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_PASSENGERS ?? false) as boolean,
				title: {
					en: "New Passengers",
					fr: "Enreg. Passagers",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-passenger-baggage",
				allowed_resources: ["passenger-baggage"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"ParcelCashier",
					"Programmer",
					"Charger",
				],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_BAGGAGES ?? false) as boolean,
				title: {
					en: "Baggage Tickets",
					fr: "Etiquettes Bagages",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-package-registration",
				allowed_resources: ["package"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_PACKAGE ?? false) as boolean,
				title: {
					en: "Package Registration",
					fr: "Enregistrement Colis",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-package-pickup",
				allowed_resources: ["package-pickup"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_PACKAGE_PICKUP ?? false) as boolean,
				title: {
					en: "Package Pickup",
					fr: "Retrait de Colis",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-package-control",
				allowed_resources: ["package"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_PACKAGE_CONTROL ?? false) as boolean,
				title: {
					en: "Package Control",
					fr: "Controle des Colis",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-operations-expense-management",
				allowed_resources: ["expense"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "MainCashier", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_OPERATION_EXPENSE_MANAGEMENT ?? false) as boolean,
				title: {
					en: "Expenses Record",
					fr: "Enreg. Dépenses",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/operation-icon.svg",
		},
	},
	// autopark
	{
		name: "autopark",
		allowed_resources: [
			"expedition",
			"vehicle",
			"driver",
			"technical-visit",
			"maintenance",
			"beverage",
		],
		user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "Programmer"],
		route: "workspace-autopark-overview",
		enable: (process.env.VUE_APP_ENABLE_AUTOPACK_OVERVIEW ?? false) as boolean,
		title: {
			en: "Auto Park",
			fr: "Parc Automobile",
		},
		elements: [
			{
				route: "workspace-autopark-vehicle",
				allowed_resources: ["vehicle"],
				user_roles: ["Root", "Admin", "CoAdmin", "Programmer", "SecondAdmin"],
				enable: (process.env.VUE_APP_ENABLE_AUTOPACK_VEHICLE ?? false) as boolean,
				title: {
					en: "Vehicles",
					fr: "Véhicules",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-technical-visit",
				allowed_resources: ["technical-visit"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_AUTOPACK_TECHNICAL_VISIT ?? false) as boolean,
				title: {
					en: "Technical Visit",
					fr: "Visites Techniques",
				},
				second_title: {
					en: "Technical Visit",
					fr: "Visites Technique",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			// {
			// 	route: "workspace-autopark-providers",
			// 	user_roles: ["Root", "Admin", "CoAdmin"],
			// 	title: {
			// 		en: "Providers",
			// 		fr: "Fournisseurs",
			// 	},
			// 	second_title: {
			// 		en: "Providers",
			// 		fr: "Fournisseurs",
			// 	},
			// 	icon: {
			// 		active: "/media/icons/....svg",
			// 		inactive: "/media/icons/....svg",
			// 	},
			// },
			{
				route: "workspace-autopark-beverage",
				allowed_resources: ["beverage"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_AUTOPACK_BEVERAGE ?? false) as boolean,
				title: {
					en: "Beverage",
					fr: "Consommations",
				},
				second_title: {
					en: "Beverage",
					fr: "Consommations",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-maintenance",
				allowed_resources: ["maintenance"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_AUTOPACK_MAINTENANCE ?? false) as boolean,
				title: {
					en: "Maintenances",
					fr: "Entretiens",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-autopark-insurance",
				allowed_resources: ["insurance"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_AUTOPACK_INSURANCE ?? false) as boolean,
				title: {
					en: "Insurance",
					fr: "Assurances",
				},
				second_title: {
					en: "Insurance",
					fr: "Assurances",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/autopark-icon.svg",
		},
	},
	// human-resources
	{
		name: "human-resources",
		allowed_resources: ["station-staff", "salary"],
		user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "MainCashier", "Programmer"],
		route: "workspace-human-resources-overview",
		enable: (process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_OVERVIEW ?? false) as boolean,
		title: {
			en: "Human Resources",
			fr: "Ressources Humaines",
		},
		elements: [
			{
				route: "workspace-human-resources-staff",
				allowed_resources: ["station-staff"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "MainCashier", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_STAFF ?? false) as boolean,
				title: {
					en: "Staff",
					fr: "Personnel",
				},
				second_title: {
					en: "Staff",
					fr: "Personnel",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-human-resources-salary",
				allowed_resources: ["salary"],
				user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin", "MainCashier", "Programmer"],
				enable: (process.env.VUE_APP_ENABLE_HUMAN_RESOURCE_SALARY ?? false) as boolean,
				title: {
					en: "Salary",
					fr: "Paiement Salaires",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/human-resources-icon.svg",
		},
	},
	// planning
	{
		name: "planning",
		allowed_resources: ["expedition", "embarkation", "travel-route", "ticket-rate"],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"Programmer",
		],
		route: "workspace-planning-overview",
		enable: (process.env.VUE_APP_ENABLE_PLANNING_OVERVIEW ?? false) as boolean,
		title: {
			en: "Planning",
			fr: "Planifications",
		},
		elements: [
			{
				route: "workspace-planning-embarkation",
				allowed_resources: ["embarkation"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_PLANNING_EMBARKATION ?? false) as boolean,
				title: {
					en: "Embarkations",
					fr: "Embarquements",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-expedition",
				allowed_resources: ["expedition"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_PLANNING_EXPEDITION ?? false) as boolean,
				title: {
					en: "Expeditions",
					fr: "Expéditions",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-routes-and-rates",
				allowed_resources: ["travel-route", "ticket-rate"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_PLANNING_ROUTE_RATES ?? false) as boolean,
				title: {
					en: "Routes and Rates",
					fr: "Trajets et Tarifs",
				},
				second_title: {
					en: "routes and rates",
					fr: "Trajets et tarifs",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-planning-others",
				allowed_resources: ["contract-type", "responsability-function", "payment-method"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_PLANNING_OTHERS ?? false) as boolean,
				title: {
					en: "Others",
					fr: "Autres",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/planification-icon.svg",
		},
	},
	// Provider
	{
		name: "providers",
		allowed_resources: ["providers"],
		user_roles: [],
		route: "workspace-providers-overview",
		enable: (process.env.VUE_APP_ENABLE_PROVIDER_OVERVIEW ?? false) as boolean,
		title: {
			en: "Providers",
			fr: "Fournisseurs",
		},
		elements: [
			// {
			// 	route: "workspace-planning-travel-tickets",
			// 	user_roles: ["Root", "Admin", "CoAdmin"],
			// 	title: {
			// 		en: "travel tickets",
			// 		fr: "Tickets de voyage",
			// 	},
			// 	second_title: {
			// 		en: "travel tickets",
			// 		fr: "Tickets de voyage",
			// 	},
			// 	icon: {
			// 		active: "/media/icons/....svg",
			// 		inactive: "/media/icons/....svg",
			// 	},
			// },
		],
		icon: {
			active: "/media/icons/provider-icon.svg",
		},
	},
	// Report
	{
		name: "reports",
		allowed_resources: ["travel-docket", "route-docket", "shipping-docket"],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"Programmer",
		],
		route: "workspace-report-overview",
		enable: (process.env.VUE_APP_ENABLE_REPORT_OVERVIEW ?? false) as boolean,
		title: {
			en: "Reports",
			fr: "Rapports",
		},
		elements: [
			{
				route: "workspace-report-travel-dockets",
				allowed_resources: ["travel-docket"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_REPORT_TRAVEL_DOCKET ?? false) as boolean,
				title: {
					en: "Travel Dockets",
					fr: "Bordereaux de voyage",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-report-shipping-dockets",
				allowed_resources: ["shipping-docket"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_REPORT_SHIPPING_DOCKET ?? false) as boolean,
				title: {
					en: "Shipping Dockets",
					fr: "Bordereaux d'expédition",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
			{
				route: "workspace-report-route-dockets",
				allowed_resources: ["route-docket"],
				user_roles: [
					"Root",
					"Admin",
					"CoAdmin",
					"SecondAdmin",
					"MainCashier",
					"PassengerCashier",
					"ParcelCashier",
					"Programmer",
				],
				enable: (process.env.VUE_APP_ENABLE_REPORT_ROUTE_DOCKET ?? false) as boolean,
				title: {
					en: "Route Dockets",
					fr: "Bordereaux de route",
				},
				second_title: {
					en: "",
					fr: "",
				},
				icon: {
					active: "/media/icons/....svg",
					inactive: "/media/icons/....svg",
				},
			},
		],
		icon: {
			active: "/media/icons/report-icon.svg",
		},
	},
	// logout
	{
		name: "logout",
		allowed_resources: ["user-profile"],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"Programmer",
			"User",
			"Charger",
			"Driver",
		],
		route: "auth-logout",
		enable: (process.env.VUE_APP_ENABLE_SETTINGS ?? true) as boolean,
		title: {
			en: "Log Out",
			fr: "Déconnexion",
		},
		elements: [],
		icon: {
			active: "/media/icons/logout-icon.svg",
		},
	},
	// settings
	{
		name: "settings",
		allowed_resources: ["settings"],
		user_roles: ["Root", "Admin", "CoAdmin", "SecondAdmin"],
		route: "workspace-settings-overview",
		enable: (process.env.VUE_APP_ENABLE_SETTINGS ?? false) as boolean,
		title: {
			en: "Settings",
			fr: "Paramètres",
		},
		elements: [],
		icon: {
			active: "/media/icons/settings-icon.svg",
		},
	},
	// profile
	{
		name: "profile",
		allowed_resources: ["user-profile"],
		user_roles: [
			"Root",
			"Admin",
			"CoAdmin",
			"SecondAdmin",
			"MainCashier",
			"PassengerCashier",
			"ParcelCashier",
			"Programmer",
			"User",
			"Charger",
			"Driver",
		],
		route: "workspace-profile-overview",
		enable: (process.env.VUE_APP_ENABLE_PROFILE ?? false) as boolean,
		title: {
			en: "Profile",
			fr: "Profil",
		},
		elements: [],
		icon: {
			active: "/media/icons/settings-icon.svg",
		},
	},
];
