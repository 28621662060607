import { watchEffect } from "vue";
import { userInfos } from "@/state/api/userState";
import * as _ from "lodash";

export default (type: string, values: Array<string>) => {
	let access = false;

	switch (type) {
		case "visibility":
			watchEffect(() => {
				if (_.isEmpty(userInfos.value) == false) {
					const roles = userInfos.value?.roles;
					for (const role of roles) {
						if (values.includes(role.name)) {
							access = true;
							break;
						}
						access = false;
					}
				}
			});
			break;
			case "permission":
				watchEffect(() => {
					if (_.isEmpty(userInfos.value) == false) {
						const permissions = userInfos.value?.permissions;
						for (const permission of permissions) {

						if (values.includes(permission.name)) {
							access = true;
							break;
						}
						access = false;
					}
				}
			});
			break;
			case "resource":
			console.log('values: ', values);
			watchEffect(() => {
				if (_.isEmpty(userInfos.value) == false) {
					const permissions = userInfos.value?.permissions;
					for (const permission of permissions) {
						if (values.includes(permission.resource)) {
							access = true;
							break;
						} else if (values.includes("all")) {
							access = true;
							break;
						}
						access = false;
					}
				}
			});
			break;
		case "stationBelongTo":
			watchEffect(() => {
				if (_.isEmpty(userInfos.value) == false) {
					const stations = userInfos.value?.stations;
					values = values.map(String); // forcer la conversion en chaine de nombre
					for (const station of stations) {
						if (values.includes(station.id.toString())) {
							access = true;
							break;
						}
						access = false;
					}
				}
			});
			break;
		case "subscription":
			watchEffect(() => {
				if (_.isEmpty(userInfos.value) == false) {
					access = true; // values.includes("subscribed") == true ? false : true;
				}
			});
			break;

		case "payment":
			watchEffect(() => {
				if (_.isEmpty(userInfos.value) == false) {
					access = true;
				}
			});
			break;
		default:
			break;
	}
	return access;
};


