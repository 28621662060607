// Services
import callAPI from "../callApi";
import path from "../variables";

const Passengers = {
	getAll: (filter?: any) =>
		callAPI(
			"GET",
			`${path.base}/tickets/passengers${filter ? filter : ""}`,
			"",
			"getting all passengers"
		),
	create: (data: any) =>
		callAPI("POST", `${path.base}/tickets/passengers`, data, "register new passenger"),
	update: (data: any, uuid: string, station: string) =>
		callAPI(
			"PUT",
			`${path.base}/tickets/passengers/${uuid}${station}`,
			data,
			"update passenger info"
		),
	transfers: (data: any) =>
		callAPI(
			"PUT",
			`${path.base}/operation/passengers/transfers`,
			data,
			"transfer passengers process"
		),
	getWeeklyTotalStats: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/report/passengers/totals${filter ? filter : ""}`,
			"",
			"getting total weekly apassengers stats"
		),
};
;
export default Passengers;
