// Services
import callAPI from "../callApi";
import path from "../variables";

const turnover = {
	getAll: (filter: any) =>
		callAPI("GET", `${path.base}/report/stats${filter ? filter : ""}`, "", "getting global stats"),
	getMonthlyByPriod: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/report/monthly-stats-by-period${filter ? filter : ""}`,
			"",
			"getting monthly turnover stats by period(months)"
		),
};

export default turnover;
