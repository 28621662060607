// Services
import callAPI from "../callApi";
import path from "../variables";

const expenses = {
	getAll: (param: string) =>
		callAPI(
			"GET",
			`${path.base}/operation/expenses${param ? param : ""}`,
			"",
			"getting all expenses operations"
		),
	create: (data: any, param: string) =>
		callAPI(
			"POST",
			`${path.base}/operation/expenses${param ? param : ""}`,
			data,
			"create expense operation"
		),
	update: (data: any, param: string) =>
		callAPI("PUT", `${path.base}/operation/expenses/${param}`, data, "updating expense operation"),
	getAllTypes: (data: any, param: string) =>
		callAPI(
			"GET",
			`${path.base}/planning/expense-types${param ? param : ""}`,
			data,
			"getting all Expenses Types"
		),
	getTotalsStats: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/report/expenses/totals${filter ? filter : ""}`,
			"",
			"getting total expenses stats"
		),
	getTotalsStatsByPeriod: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/report/expenses/totals-by-period${filter ? filter : ""}`,
			"",
			"getting total expenses stats by period"
		),
	getMonthlyTotalsStatsByPeriod: (filter: any) =>
		callAPI(
			"GET",
			`${path.base}/report/expenses/monthly-totals-by-period${filter ? filter : ""}`,
			"",
			"getting monthly total expenses stats by period"
		),
};


export default expenses;
